import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";
import { TwoxOneWH  } from "../mixins-modules"; 



export const ContentTwoxOneVisual = styled.div`
  ${TwoxOneWH}
  .contentVideo{;
      aspect-ratio:16/9;
    }
  ${breakpoints.phone}{
    &.videoLoopBox {
    display: flex;
    flex-direction: column-reverse;
    .contentVideo{
      height:auto;
      aspect-ratio: 16:9;
      &:after{
        background:none;
      }
    }
    .mt{
      position:inherit;
      left: inherit;
      bottom:inherit;
      margin: 15px 0px;
        h2.title{
          text-shadow: inherit;
          color: ${Color.black};
          ${breakpoints.darkMode} {
            [data-theme='dark'] & {
            color: ${ColorDark.textLight};
            }
          }
        }
        .volanta {
          text-shadow: inherit;
          color: ${Color.mainColor};
          ${breakpoints.darkMode} {
            [data-theme='dark'] & {
            color: ${ColorDark.mainColor};
            }
          }
        }
        .data-txt > p.name, .icon-paywall-box p  {
          color: #555555;
          ${breakpoints.darkMode} {
            [data-theme='dark'] & {
            color: ${ColorDark.textLight};
            }
          }
        }
      }
    }
  }



  .mt {
    h2.title, .volanta{
        font-size: 28px;
        line-height: 32px;
        /* margin-bottom:15px; */
        ${breakpoints.phone} {
          font-size: 22px;
          line-height: 23px;
      }
    }
  }
  picture{
    section{
      top: 0;
      .futbol-header{
        align-items: flex-start;
        .futbol-header-info {
          flex-direction:column-reverse;
        }
      }
      .rubgy-box {
        .header{
          align-items: flex-start;
          .header-info{
            flex-direction:column-reverse;
            .status {
              p {
                padding-top: 0px;
                padding-bottom: 6px;
              }
            }
          }
        }
      }
    }
    .tenis-mam {
      top: 40px;
      right: 20px;
    }
  }
  .contentVideo{
    height:auto;
    video{
      height:auto;
    }
  }
`;
